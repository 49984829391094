import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios"
import { useNavigate } from 'react-router-dom'
// axios for fetching
import { useQuery } from "react-query"
import Swal from "sweetalert2";
import'./Data.css'
import TopNav from './TopNav'


export default function App() {
  const navigate=useNavigate()
  const spinner = {
    width: "6rem", 
    height: "6rem"
}
  const markerIcon =
  new L.Icon({
    iconSize: [12, 20],
    iconAnchor: [10, 41],
    iconUrl: require("../assets/fire.png"),
  });
  
  const user_token = JSON.parse(localStorage.getItem('user_token'));
  const user_org_name = JSON.parse(localStorage.getItem('user_org'));
  const user_username = JSON.parse(localStorage.getItem('user_username'));
  const getData = async () => {
  const getApiData = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/analyticals`,{"org":user_org_name, "username":user_username},{headers : {"Authorization" : `token ${user_token}`}})
  return(getApiData.data)
  };

  const position = [33.9448873, -83.8012194]
  const {data:coordinatesData, isLoading: coordinatesLoading, error: coordinatesError} = useQuery("getApiData", getData)

  if (coordinatesLoading)return (<>
    <div class="d-flex justify-content-center">
        <div className="spinner-border text-dark mt-5 display-5" style={spinner} role="status"></div>
    </div>
</>
)
  if (coordinatesError)return<>
  {Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="mailto:evolvingtechnologies1@outlook.com?subject=Error Loading Page&body=Hey there, I am running into an error accessing this page. Please help me resolve issue.">Why do I have this issue?</a>'
  }).then((result) => {
      if (result.isConfirmed) {
          navigate(-1)
          setTimeout(() => {
              navigate(0)
          }, 3);
              }})}</>
  return (
  <div>
    <TopNav prefBgColor="primary" prefFgColor="dark"/>
    <div className="mt-5">
      <div className="d-flex justify-content-center mb-5 col-12">
        <p className="display-1 fw-bolder">Light it up<br /> 
        <span class="fire col-12 p-4">
          <span class="flame base p-4">🔥🔥🔥</span>
          <span class="flame animate p-4">🔥🔥🔥</span>
          <span class="flame animate p-4">🔥🔥🔥</span>
          <span class="flame animate p-4">🔥🔥🔥</span>
        </span>
      </p>
      </div>
      <div className="row pt-5">
        <div className="col-12 border border-danger">
          <MapContainer center={position} zoom={2} style={{ height: "100vw", width: "100%"}}>
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            {coordinatesData.map((coordinata) => {return <>
            <Marker key={coordinata[7]} position={coordinata.slice(0, 2)} scrollWheelZoom={true} icon={markerIcon}>
            <Popup>
                <h3><b>First name: </b>{coordinata[2]}<br /></h3>
                <h3><b>Location: </b>{coordinata[3]}<br /></h3>
                <h3><b>Outreach Category: </b>{coordinata[4]}<br /></h3>
                <h3><b>Minister: </b>{coordinata[5]}<br /></h3>
                <h3><b>What happened: </b>{coordinata[6]}<br /></h3>
                {/* Example data: 0:32.136, 1:-81.208, 2: "StevensonG1234", 3:"WalMart", 4: "Test", 5: "Stevenson", 6:"Testing",:17 */}
            </Popup>
            </Marker></>})}
          </MapContainer>
        </div>
      </div>
    </div> 
  </div>
  );
}
