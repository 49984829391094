import React from 'react'
import {Outlet} from 'react-router-dom'

function MainLayout() {
  return (
    <div><Outlet/></div>
  )
}

export default MainLayout

// page for passing in the components that we created
// this is required for react-router-dom