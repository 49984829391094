import React from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {useForm} from "react-hook-form"
import PageHeader from "../reusableComponents/PageHeader"
import FormInputBox from "../reusableComponents/FormInputBox"
import PageButton from "../reusableComponents/PageButton"
import Swal from 'sweetalert2'
import "./LoginIdentity.css"
import axios from 'axios'

const LoginIdentity = () => {
    const navigate=useNavigate()
    // used for navigation
    const {register, handleSubmit, reset} = useForm()
    // https://react-hook-form.com/api/useform/formstate
    // When you call watch, you pass it the registered name you want it to watch, this watches it's every input
    // You can also keep it all blank and it watches everything as on object
    // console.log(watch('registerValue'))
    // handleSubmit() => gets all the input values and gives it to us. (this does not catch every press)
    // repeatable function that will be used for API 
    // reset resets the form value to blank 
    const GatherData = async(data) => {
      try{
        const api_resp = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login_verification`,data,{ }) 
        // api call for login_verification
        const token = api_resp.data['token']
        const name = api_resp.data['name']
        const username = api_resp.data['username']
        const email = api_resp.data['email']
        const org = api_resp.data['org']
        // post request will return a series of data, we narrow it down to the data that we need
        localStorage.setItem('user_token', JSON.stringify(token));
        localStorage.setItem('user_name', JSON.stringify(name));
        localStorage.setItem('user_username', JSON.stringify(username));
        localStorage.setItem('user_email', JSON.stringify(email));
        localStorage.setItem('user_org', JSON.stringify(org));
        // saving the user publc profile info to the local storage in object format.
        if (token === JSON.parse(localStorage.getItem('user_token'))){
          // this is the "if the local cache token matches db token ^^^"
          // if the user token that is stored in local cache is the same as the token that is stored in the database, then access is allowed
          navigate('/home')
          // if user authentication is successful. this will auto send them to home page, if not, it will show popup and back to login page
        }
       }catch(error){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Invalid Credentials',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText:'Try again!',
              footer: '<a href="mailto:evolvingtechnologies1@outlook.com?subject=Account Creation Error&body=Please provide the first name, last name, username, email that you provided on the form. A tech will contact you soon">Click here if issue persist more then 3 times</a>'
            })
          // alert box that user will receive upon completion of account creation
          // https://sweetalert2.github.io/#icons
          reset()
          // reset resets the form value to blank
        }
    }
    const Create_Account = () => {
      navigate("/create")
      }
    const PasswordReset = () => {
      window.open(`${process.env.REACT_APP_BACKEND_URL}/accounts/password_reset`)
      }
    return (
        <Container className="w-75 h-100 mx-auto">
            {/* actual form */}
            <PageHeader headerTitle="Welcome"/>
            <form className="row form rounded mx-auto" onSubmit={handleSubmit(GatherData)}>
                {/* GATHER FORM DATA WITH THE EVENT HANDLER */}
              <FormInputBox 
                styles={{ maxHeight: 35 }}
                cols={1}             
                register={register}
                registeredName={"username"}         
                placeholder="Enter username"
                inputType="text"   
                autoCapitalize = 'none'   
                />
              <input 
                styles={{ maxHeight: 35 }}
                cols={1} 
                {...register("password")}
                registeredName={"password"} 
                placeholder="Enter password"
                type="password"
                />
                {/* submit button to form */}
              <div className="row mt-3 mb-5 pb-2 w-75 mx-auto border border-light border-0 rounded">
                <PageButton 
                  styleValue = "font-weight-bold display-5 mt-5 mb-3 btn btn-primary"
                  buttonText = "Login"
                />
              </div>
            </form>
            <div className="row w-75 mx-auto border border-light border-0 rounded">           
            <PageButton 
                  styleValue = "font-weight-bold display-5 btn btn-dark"
                  eventHandlerFunction = {Create_Account}
                  buttonText = "Create"
                />
            </div>
            <div className="row w-75 mx-auto border border-light border-0 rounded mt-5">           
            <PageButton 
                  styleValue = "font-weight-bold display-5 btn btn-danger"
                  eventHandlerFunction = {PasswordReset}
                  buttonText = "Forgot password"
                />
            </div>
        </Container>
      )
}
export default LoginIdentity