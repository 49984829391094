import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//used to navigate between pages in react. npm i react-router-dom --save. The (--save) is optional
//as npm 5.0 automatically adds the package as a dependency
import 'bootstrap/dist/css/bootstrap.min.css';
//import bootstrap into the project. npm install bootstrap
import {Image, Button, Container, Stack,}from 'react-bootstrap'
import {CardGroup, Card, CardBody, CardText,}from 'reactstrap'
//import react-bootstrap. This is better then using the CDN bootstrap because it is react native. 
//However, you might need both to accomadate. 
//same way used to define this and regular bootstrap, but you use capital letters for this
import logo from '../assets/raw_logo.png';
import data from '../assets/data.png';
//import your image from a file verse calling the entire file. 
//You use (2) dots to go up one folder. (4) dots to go up two folder
//one folder ../ two folder  ../../
import text from '../assets/send_text.jpg';
import register from '../assets/register.jpg';
import outreach_Logo from '../assets/2535_black.jpg';
import firetracker from '../assets/firetracker.png';
// importing pictures as a regular name versus calling the entire file path in the code
import axios from "axios"
// axios for fetching

const Homepage = () => {
    const [defaultVerse, setDefaultVerse] = useState("'For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life.'")
    //use this useState value to change the value of the verse portion in react. 
    //Anytime a DOM changes in react, dynamically, a hook is often used
    const navigate = useNavigate();
    //use this to call routes into the react
    //array verse list. I will want to make this an API call verses hardcoding the scripture
    const user_name = JSON.parse(localStorage.getItem('user_name'))
    const user_org = JSON.parse(localStorage.getItem('user_org'))
    const RandomVerseSelection = async()=>{
        try{
            const apiFetch = await axios.get('https://labs.bible.org/api/?passage=random&formatting=plain')
            // API call to get random verse
            const apiData = apiFetch.data
            // retrieving data from API call
            setDefaultVerse(apiData)
            // Destructor array. State update
        }catch(err){alert(err)}
    }   
    return (
        <Container  className="App">
            <h1 className='mt-5'>Welcome {user_name} from {user_org}</h1>
            <Stack gap={5}>
                <Image fluid src={logo} alt="Rise And Walk Ministry logo"/>
                {/* logo is defined as a variable above */}
                <div><h3><u>{defaultVerse}</u></h3></div>
                {/* this is where the verse is hooked */}
                <Button onClick={RandomVerseSelection} variant="dark"><h2>Show a new verse</h2></Button>
                {/* a function called UpdatedVerse that is part of the useState. A parameter is pushed to UpdatedVerse and will be used */}
                <CardGroup>
                    <Card className="border border-primary border-3">
                        <Image fluid rounded src={register} alt="Register keyboard button"/>
                        <CardBody>
                            <CardText tag="h5">Register New Connections</CardText>
                            <Button onClick={() => navigate("register")}>Register</Button>
                            {/* if you press this button, it will navigate to the register page. it will be a component */}
                            {/* navigate is part of the code that takes an argument */}
                        </CardBody> 
                    </Card>
                        <Card className="border border-success border-3">
                            <Image fluid rounded src={text} alt="Send text message image"/>
                            <CardBody>
                                <CardText tag="h5">Compose Message To Send</CardText>
                                <Button className="btn-success" onClick={() => navigate("select")}>Compose Message</Button>
                            </CardBody>
                        </Card>
                    <Card className="border border-danger border-3">
                        <Image fluid rounded src={outreach_Logo} alt="25:35 Outreach base logo"/>
                        <CardBody>
                            <CardText>Outreach Base Registration/Send Messages</CardText>
                            <Button variant="danger" onClick={() => navigate("outreach")}>Outreach Update</Button>
                        </CardBody>
                    </Card>
                    <Card className="border border-warning border-3">
                        <Image fluid rounded src={firetracker} alt="Data Analyticals"/>
                        <CardBody>
                            <CardText>Map</CardText>
                            <Button variant="warning" onClick={() => navigate("data")}>Fire Tracker</Button>
                        </CardBody>
                    </Card>
                    <Card className="border border-dark border-3">
                        <Image fluid rounded src={data} alt="Data Analyticals"/>
                        <CardBody>
                            <CardText>Database</CardText>
                            <Button variant="dark" onClick={() => navigate("admin")}>Admin</Button>
                        </CardBody>
                    </Card>
                </CardGroup>
            </Stack>
        </Container>
        )
    } 

    
export default Homepage
