import React, {useContext} from 'react'
import {Button, Container, Stack}from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { SelectMessageOptionContext } from '../context/SelectMessageOption'
import TopNav from './TopNav'

function Select() {
    const navigate=useNavigate()
    const [selectOption, setSelectOption] = useContext(SelectMessageOptionContext)
    const SendCategory = async(buttonSelection) => {
        setSelectOption(buttonSelection)
        navigate('/home/send')
    }
    return (
        <div>
            <TopNav prefBgColor="primary" prefFgColor="dark"/>
            <Container  className="mt-5 mb-5">
                {/* send email is not currently used. It is for future where the API will be added to actually send the messages. */}
                <Stack gap={3} className="mb-5">
                    <Button variant="success" onClick={()=>SendCategory("category_api")} className="p-5" style={{ width: '100%' }}><h3>Send by Category</h3></Button>
                    {/* will retrieve all the category list from the API endpoint */}
                    <Button variant="dark" onClick={()=>SendCategory("contact_api")} className="p-5" style={{ width: '100%' }}><h3>Send by Name</h3></Button>
                    {/* will retrieve all the contact list from the API endpoint */}
                    <Button variant="warning" onClick={()=>SendCategory("outreach_api")} className="p-5" style={{ width: '100%' }}><h3>Send by Ambassador</h3></Button>
                    {/* will retrieve all the outreach list from the API endpoint. */}
                    <Button variant="light" onClick={()=>SendCategory("all_outreach")} className="p-5 border border-dark border-3" style={{ width: '100%' }}><h3>Send by Outreach Contact</h3></Button>
                    {/* will retrieve all the outreach list from the API endpoint. */}
                </Stack>
            </Container>
        </div>
        )
    }

export default Select
