import React from 'react'
import{Card, Stack, Container, Button}from'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faYoutube, faFacebook, faTiktok, faInstagram} from "@fortawesome/free-brands-svg-icons";


const TemplateFooter = ()=>{
  const Logout = ()=>{
    localStorage.clear()
    window.open("/login", "_parent")
  }
    return (
      <Container className="App">
        <Button onClick={Logout} className="btn btn-dark mt-5 mb-5">Logout</Button>
        <Stack>
          <Card>
            <Card.Header>John 5:8 Jesus said unto him, "<span className="text-danger font-weight-bold text-center">rise take up your bed and walk</span>".</Card.Header>
            <Card.Body>
              <Card.Title className="mt-4">Rise And Walk Ministry Team</Card.Title>
              </Card.Body>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <Card.Footer className="text-muted" style={{ whiteSpace: 'nowrap' }}>Deisgned and Developed by <a href="https://www.evovletech.com" rel="noopener noreferrer" target="_blank">Evolving Technologies</a></Card.Footer>
              </div>
          </Card>
        </Stack>
        <Stack>
          <p className="social-container mx-auto row pt-4">
            <a href="https://www.youtube.com/channel/UCiQlfyuFxrV0oR0tWd7Z8HQ" target='_blank' rel="noopener noreferrer" className="youtube social col"><FontAwesomeIcon icon={faYoutube} size="3x" color="#ff0000"/></a>
            <a href="https://www.facebook.com/riseandwalk.atl"  target='_blank'  rel="noopener noreferrer" className="facebook social col"><FontAwesomeIcon icon={faFacebook} size="3x" color="#1877f2"/></a>
            <a href="https://www.instagram.com/riseandwalkministries/"  target='_blank'  rel="noopener noreferrer" className="instagram social col"><FontAwesomeIcon icon={faInstagram} size="3x" color="#c1558b"/></a>
            <a href="https://www.tiktok.com/@raw2535?lang=en"  target='_blank'  rel="noopener noreferrer" className="twitter social col"><FontAwesomeIcon icon={faTiktok} size="3x" color="#010101"/></a>
          </p>
        </Stack>
      </Container>

    )
// } else {
//   window.open('/login')
// }
}

export default TemplateFooter