import React from 'react'
import { Navigate } from "react-router-dom";
import { useQuery } from "react-query"
import axios from 'axios'
import Swal from 'sweetalert2'

function AuthRoutes(props) {
    const user_username = JSON.parse(localStorage.getItem('user_username'))
    const api_token = JSON.parse(localStorage.getItem('user_token')) 
    const {children} = props
    const getTokenApiData = async () => {
       const getData = await axios.post("https://go-all-nations-2-backend.herokuapp.com/token_validation",{"username":user_username},{headers : {"Authorization" : `token ${process.env.REACT_APP_API_TOKEN}`}})
        return(getData.data['0'])
        };
   const {data:tokenApiGet, isLoading:tokenApiLoading , error: tokenApiError} = useQuery("getData", getTokenApiData)
    if (tokenApiLoading)return<>Fetching data</>
    if (tokenApiError)return<>
       {Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please login if you have not logged in or re-login if you already logged in!',
            showCancelButton: false,
            confirmButtonText: 'Go to login page'
        }).then((result) => {
            if (result.isConfirmed) {window.open("login", "_parent")}})
        }</>
    if (!api_token){
        // if cannot authenticate
        return (
            <Navigate to="/login"/>
        )
    }
    return <>{children}</>
}

export default AuthRoutes