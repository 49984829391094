import React from 'react'
import {Container, Stack} from 'react-bootstrap'
import {useForm} from "react-hook-form";
import FormInputBox from "../reusableComponents/FormInputBox"
import PageHeader from "../reusableComponents/PageHeader"
import TopNav from './TopNav'
import FormInputBoxWithValidation from "../reusableComponents/FormInputBoxWithValidation";
import PageButton from "../reusableComponents/PageButton";
import axios from 'axios'


const Outreach = () => {
  var userLatitude = ""
  var userLongitude = ""
  const api_token = JSON.parse(localStorage.getItem('user_token'));
  const user_username = JSON.parse(localStorage.getItem('user_username'));
  const user_email = JSON.parse(localStorage.getItem('user_email'));
  const user_org = JSON.parse(localStorage.getItem('user_org'))
  const userName = JSON.parse(localStorage.getItem('user_name'))
  const {register, formState: { errors }, handleSubmit, reset} = useForm()
  navigator.geolocation.getCurrentPosition(function(position) {
      userLatitude = position.coords.latitude
      userLongitude = position.coords.longitude
  })
  const GatherData = async(data, e)=>{
    e.preventDefault()
    const userTime =  new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })
    data.latitude = userLatitude
    data.longitude = userLongitude
    data.username = user_username
    data.email = user_email
    data.org = user_org
    data.user_name = userName
    data.userTime = userTime
    setTimeout(async() => {await axios.post(`${process.env.REACT_APP_BACKEND_URL}/outreach_registration_api`,data,{'Authorization': `token ${api_token}`})}, 8000);
    reset()
    // refresh the page to clear the form value
  }
    return (
      <div>
        <TopNav prefBgColor="primary" prefFgColor="dark"/>
        <Container className="w-75 h-100 mx-auto">
          <div className="col">
            <PageHeader className='mt-5 mb-5' headerTitle="Outreach Registration Form"/>
            <form className="row form rounded mx-auto" onSubmit={handleSubmit(GatherData)}>        
              {/* GATHER FORM DATA WITH THE EVENT HANDLER */}
              <Stack gap={4}>
                <div className="row mt-5">
                  <span className="col-1 mt-3"><i className="fa fa-user bigicon text-primary col"></i></span>
                  <div className="col-10">
                    <FormInputBoxWithValidation
                      register={register}
                      errors={errors}
                      errorValidation={{ required: true }}
                      registeredName={"firstName"}
                      placeholder="First Name"
                      inputType="text"
                      required={true}
                      characterValue={20}
                      patternValue={/^[A-Za-z- ]+$/i}
                      patternMessage={"can only have [A-Za-z- ] as charachter"}
                    />                                
                  </div>
                </div>
                <div className="row">
                    <span className="col-1 mt-3"><i className="fa fa-users bigicon text-success"></i></span>
                    <div className="col-10">
                      <FormInputBoxWithValidation
                        register={register}
                        errors={errors}
                        errorValidation={{ required: true }}
                        registeredName={"lastName"}
                        placeholder="Last Name"
                        inputType="text"
                        required={false}
                        characterValue={20}
                        patternValue={/^[A-Za-z- ]+$/i}
                        patternMessage={"can only have [A-Za-z- ] as charachter"}
                      />
                    </div>
                </div>
                <div className="row">
                    <span className="col-1 mt-3"><i className="fa fa-phone-square bigicon text-dark"></i></span>
                    <div className="col-10">
                      <FormInputBoxWithValidation
                        register={register}
                        errors={errors}
                        errorValidation={{ required: true }}
                        registeredName={"phoneNumber"}
                        placeholder="Phone Number"
                        inputType="tel"
                        required={true}
                        characterValue={30}
                        patternValue={/^(\+\d{1,3}[- ]?)?\d{10}$/}
                        patternMessage={"Allowed characters are digits only, no dash. 1114445555"}
                      />
                    </div>
                </div>
                <div className="row">
                    <span className="col-1 mt-3"><i className="fa fa-location-arrow bigicon text-dark"></i></span>
                    <div className="col-10">
                      <FormInputBoxWithValidation
                        register={register}
                        errors={errors}
                        errorValidation={{ required: true }}
                        registeredName={"location"}
                        placeholder="Location"
                        inputType="text"
                        required={true}
                        characterValue={60}
                        patternValue={/^(.|\n)*?$/}
                        patternMessage={""}
                      />
                    </div>
                </div>
                <div className="row mt-4">
                    <span className="col-12 mt-5 text-center"><i className="fa fa-pencil-square-o bigicon text-warning"></i></span>
                    <div className="col-12">
                      <FormInputBox 
                          heightSize="40vw"
                          styles={{ maxHeight: 200 }}
                          cols={100}
                          register={register}
                          registeredName={"notes"}         
                          placeholder="What stood out to you about this interaction. Specifics about the interaction" 
                          inputType="text"    
                          className="square border border-warning text-left m-5" 
                        /> 
                    </div>
                </div>
              </Stack>
                <div className="row mb-4 mt-4 form-group d-flex justify-content-center">
                  <div className="col-12 form-group d-flex justify-content-center">
                    <select 
                      {...register("outreachCategory", { required: 'Outreach Category is required' })}
                      className="btn btn-primary dropdown-toggle form-group mt-3 mb-3 text-center pl-5"> 
                      <option value="placeholder">--Outreach Category--</option>
                      <option value="Prayer">Prayer</option>
                      <option value="Healing">Healing</option>
                      <option value="Prophetic">Prophetic Experience</option>
                      <option value="Convert">New Convert</option>
                      <option value="All">All</option>
                      <option value="Test">Test</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row-12">
                    <div className="col text-center">
                      <PageButton buttonText="Submit" styleValue="btn btn-success btn-lg"/>
                    </div>
                </div>
            </form>
          </div>
        </Container>
      </div>
  )
}

export default Outreach

