import React, {useContext, useState} from 'react'
import axios from "axios"
import { useQuery } from "react-query"
import'./Admin.css'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import TopNav from './TopNav'
import { GetContactInfoContext } from '../context/GetContactInfo'

function Admin() {
    const navigate=useNavigate()
    const user_username = JSON.parse(localStorage.getItem('user_username'));
    const user_token = JSON.parse(localStorage.getItem('user_token'));
    const user_org = JSON.parse(localStorage.getItem('user_org'));
    // retrieves data from cache
    const [contactInfo, setContactInfo] = useContext(GetContactInfoContext)
    const [outreachInfo, setOutreachInfo] = useState()
    const [outreachView, setOutreachView] = useState(false)
    const [contactView, setContactView] = useState(false)

    const spinner = {
        width: "6rem", 
        height: "6rem"
    }
    const exportAllContact = async () => {
        try{
            const exportAllContactApi = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/export_contacts`,{"org":user_org, "username":user_username},{headers : {"Authorization" : `token ${user_token}`}})   
            return(exportAllContactApi.data)
            }
        catch(err){
            console.log("no access")
    }
}
        // list all the contact in the database that matches the user org
    const exportToExcel = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const fileExtension = 'xlsx'
        const ws = XLSX.utils.json_to_sheet(exportAllContactApiData);
        const wb = { Sheets: { "data": ws}, SheetNames: ["data"]}
        const excelBuffer = XLSX.write(wb, { bookType:"xlsx", type: "array"});
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs (data, "All contacts from Database" + fileExtension)
        }
    const getUserData = async (e) => {
        const getSingleUserDataApi = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin_edit_user`,{"user":e.target.id, "username":user_username},{headers : {"Authorization" : `token ${user_token}`}})
        const apiDataResponse = getSingleUserDataApi.data[0]
        setContactInfo(apiDataResponse)
        // fetches the specific user entry in the database. The specific user that was selected from screen.
        const contact_id = (apiDataResponse.id)
        const contact_notes_info = (apiDataResponse.contact_notes_info)
        const first_name_info = (apiDataResponse.first_name_info)
        const last_name_info = (apiDataResponse.last_name_info)
        const phone_number_info = (apiDataResponse.phone_number_info)
        const category_name = (apiDataResponse.category_name)

        Swal.fire({
            title: 'Edit?',
            html: '<h4><strong>First Name:</strong> ' + first_name_info +  '<br><strong>Last Name:</strong> ' + last_name_info + '<br><strong>Phone number:</strong> ' + phone_number_info +  '<br><strong>Contact notes:</strong> ' + contact_notes_info + '<br><strong>Contact Id:</strong> ' + contact_id + '<br><strong>Category Group:</strong> ' + category_name,
            icon: 'question',
            showCancelButton: true,
            cancelButtonColor: 'black',
            confirmDenyColor: '#d33',
            denyButtonText: "Delete",
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText: "Go back",
            confirmButtonText: 'Edit'
            }).then((result) => {
            if (result.isConfirmed) {
                navigate(`AdminEditForm/${e.target.id}`)
                    }
            else if (result.isDenied){
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin_delete_user`,{"org":user_org, user:e.target.id},{headers : {"Authorization" : `token ${user_token}`}})  
                window.location.reload(true) 
            }    
                }
        )}
    const getOutreachData = async (e) => {
        const getSingleOutreachDataApi = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin_edit_user`,{"user":e.target.id, "username":user_username, "view":"outreach"},{headers : {"Authorization" : `token ${user_token}`}})
        const apiDataResponse = getSingleOutreachDataApi.data[0]
        setOutreachInfo(apiDataResponse)
        // fetches the specific user entry in the database. The specific user that was selected from screen.
        const outreach_first_name = (apiDataResponse.outreach_first_name)
        const outreach_last_name = (apiDataResponse.outreach_last_name)
        const outreach_phone_number = (apiDataResponse.outreach_phone_number)
        const outreach_spot = (apiDataResponse.outreach_spot)
        const outreach_category = (apiDataResponse.outreach_category)
        const minister_category = (apiDataResponse.minister_category)
        const outreach_date = (apiDataResponse.outreach_date)
        const contact_notes = (apiDataResponse.contact_notes)


        Swal.fire({
            title: 'Outreach Contact',
            html: '<h4><strong>First Name:</strong> ' + outreach_first_name +  '<br><strong>Last Name:</strong> ' + outreach_last_name + '<br><strong>Phone number:</strong> ' + outreach_phone_number + '<br><strong>Location:</strong> ' + outreach_spot + '<br><strong>Ambassador:</strong> ' + minister_category + '<br><strong>Encounter:</strong> ' + outreach_category + '<br><strong>Location:</strong> ' + outreach_spot +  '<br><strong>Date:</strong> ' + outreach_date + '<br><strong>Contact notes:</strong> ' + contact_notes,
            confirmButtonColor: 'black',
            confirmButtonText: 'Go back'
        })
        }
    const getAllContactData = async () => {
        try{
            const getAllContactDataApi = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/all_contact`,{"org":user_org, "username":user_username},{headers : {"Authorization" : `token ${user_token}`}})
            return(getAllContactDataApi.data)
        }catch(err){
            console.log("no access")
        }
        }   
    const getAllOutreachContactData = async () => {
        const getAllOutreachContactDataApi = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/all_outreach`,{"org":user_org, "username":user_username},{headers : {"Authorization" : `token ${user_token}`}})
        return(getAllOutreachContactDataApi.data)
        }  
 
    const setView = (e) => {
        if (e === 'contacts'){
            setContactView(true)
            setOutreachView(false)
        }
        else if (e === 'outreach'){
            setContactView(false)
            setOutreachView(true)
        }        
    }
    const {data:contactData, isLoading: contactLoading, error: contactError} = useQuery("getAllContactDataApi", getAllContactData)
    const {data:outreachData, isLoading: outreachLoading, error: outreachError} = useQuery("getAllOutreachContactDataApi", getAllOutreachContactData)
    const {data:exportAllContactApiData, isLoading: exportAllContactApiLoading, error: exportAllContactApiError} = useQuery("exportAllContactApi", exportAllContact)
    if (contactLoading, exportAllContactApiLoading, outreachLoading)return (<>
            <div class="d-flex justify-content-center">
                <div className="spinner-border text-dark mt-5 display-5" style={spinner} role="status"></div>
            </div>
        </>
    )
    if (contactError, exportAllContactApiError, outreachError)return<>
        {Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            footer: '<a href="mailto:evolvingtechnologies1@outlook.com?subject=Error Loading Page&body=Hey there, I am running into an error accessing this page. Please help me resolve issue.">Why do I have this issue?</a>'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(-1)
                setTimeout(() => {
                    navigate(0)
                }, 3);
                    }})}</>
  return (
    <div>
   
        <TopNav prefBgColor="light" prefFgColor="white"/>
        <div className="containerFluid">
            <div className="d-flex align-items-center h-100 mb-5 pt-5 pb-5 bg-primary">
                <p className="display-3 col-8 p-3">Contact <b>Management</b></p>
                <button className="btn btn-light col-4" onClick={exportToExcel}><i className="material-icons text-success">&#xE24D;</i> Export to Excel</button>  
            </div>
            <div className="row text-center">
            <h1 className="d-flex justify-content-center">Select a view</h1>
                <button className="bg-dark col p-5 text-white p-5 m-5 h-100 w-100 border border-5 rounded" onClick={()=>{setView('outreach')}}><h1>Outreach Contacts</h1></button>
                <button className="bg-light btn-large text-dark col p-5 m-5 h-100 w-100 border border-dark border-5 rounded" onClick={()=>{setView('contacts')}}><h1>Registered Contacts</h1></button>
            </div>
            {contactView ? <>
            <h1 className="d-flex justify-content-center">Select Contact First Name</h1>
            <div className="text-center row pt-4 border bg-dark text-white"> 
                <h4 className="col-4">First name</h4>
                <h4 className="col-4">Last name</h4>
                <h4 className="col-4">Category</h4>
            </div>
            
            {contactData.map((eachName)=>{ 
                return(
                    <div className="text-center row pt-4 border"> 
                        <h4 className="col-4" onClick={getUserData} id={eachName['id']}>{eachName['first_name_info']}</h4>
                        <h4 className="col-4" id={eachName['id']}>{eachName['last_name_info']}</h4>
                        <h4 className="col-4" id={eachName['id']}>{eachName['category_name']}</h4>
                    </div>
                )
            })}</>
            : <></>}
            {outreachView ? <>
            <h1 className="d-flex justify-content-center">Select Contact First Name</h1>
            <div className="text-center row pt-4 border bg-dark text-white"> 
                <h4 className="col-3">First name</h4>
                <h4 className="col-3">Last name</h4>
                <h4 className="col-3">Ambassador</h4>
                <h4 className="col-3">Location</h4>
            </div>
            {outreachData.map((eachName)=>{
                return(
                    <div className="text-center row pt-4 border"> 
                        <h4 className="col-3" onClick={getOutreachData} id={eachName['id']}>{eachName['outreach_first_name']}</h4>
                        <h4 className="col-3" id={eachName['id']}>{eachName['outreach_last_name']}</h4>
                        <h4 className="col-3" id={eachName['id']}>{eachName['minister_category']}</h4>
                        <h4 className="col-3" id={eachName['id']}>{eachName['outreach_spot']}</h4>
                    </div>
                )
            })}</>:<></>}
                
        </div> 
    </div>    
    )
}


export default Admin