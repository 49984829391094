import React from "react";

const FormInputBoxWithValidation = (props) => {
  const { register, registeredName, errors, placeholder, inputType, required, characterValue, patternValue, patternMessage, extraRules, disabled} = props;
  const FormValidation = (required_boolean, placeholder, character_value, pattern_value, pattern_message) => {
    // required_boolean is either true or false. This is for the purpose of react-hook-forms
    // input name is used for the name of the actual input field to feedback to error message
    // character_value is the integer value that will be assinged to max value
    // pattern value is used for regex that will be used for field validation
    // pattern message is used for feedback of error message that will be sent to user if they don't meet the validation criteria
    // input state is the "state" and "set state" value

    return {
      required: {
        value: required_boolean,
        message: `${placeholder} is required`
      },
      maxLength: {
        value: character_value,
        message: `cannot excced more then ${character_value} characters`
      },
      pattern: {
        value: pattern_value,
        message: pattern_message
      }

      // these are the react-hook-forms field/values for validation
        };
    };

  return (
    <div>
      <input
        disabled={disabled}
        {...register(registeredName,{...FormValidation(required,placeholder,characterValue,patternValue,patternMessage),...extraRules})}
        placeholder={placeholder}
        type={inputType}
        className="mt-3 w-100 mx-auto border-0"
        autoComplete="on"
      />
      <p className="d-flex justify-content-center text-danger">{errors[registeredName] && errors[registeredName].message}</p>
      {/* ^^ This Will look into errors and retrun it No need for functions */}
    </div>
  );
};

export default FormInputBoxWithValidation;
