const FormInputBox = (props) => {
  const { register, registeredName } = props;
      return (
        <textarea
        placeholder={props.placeholder} 
        type={props.inputType}
        {...register(registeredName)}
        className="mt-3 mb-5 w-100 text-center mx-auto border-0"
        autoComplete="on"
        style={{ resize: "none", maxHeight:30, width:props.widthSize, height:props.heightSize, ...props.styles }}
      // assigns defualt value
      // I change dthe input to a textarea, text area you can say amount of cols you want  */}
      // I also added resize to be none in styles so it looks line a input, and from there i added stays as a prop
        />        
      )
  }

export default FormInputBox

