import React from 'react'
import {Image, Button, Container, Stack}
from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
//use this to call routes into the react
import notfound from '../assets/notfound.jpg';

function NotFound() {
  const navigate = useNavigate()
  //use this to call routes into the react
  return (
    <Container>
      {/* bootstrap */}
      <Stack gap={5}>
        {/* stacks vertical, with a gap of 5 */}
        <h1 className="text-center w-75 mt-3 pt-2 pb-2 display-5 mx-auto rounded">Page Not Found</h1>
        <Image fluid rounded src={notfound} alt="Page Not Found Image"/>
        <Button variant="warning" onClick={()=>(navigate("home"))}><h1>Go back home</h1></Button>
        {/* if you press this button, it will navigate to the register page. it will be a component */}
        {/* navigate is part of the code that takes an argument */}
      </Stack>
    </Container>
  )
}
export default NotFound