import React,{useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import {Container, Stack,} from 'react-bootstrap'
import {useForm} from "react-hook-form"
import TopNav from './TopNav'
import PageHeader from "../reusableComponents/PageHeader"
import FormInputBoxWithValidation from "../reusableComponents/FormInputBoxWithValidation"
import FormInputBox from "../reusableComponents/FormInputBox"
import PageButton from "../reusableComponents/PageButton";
import axios from "axios"
// axios for fetching
import { useQuery } from "react-query"
import Swal from 'sweetalert2'
import { GetContactInfoContext } from '../context/GetContactInfo'


function AdminEditForm() {
  const [contactInfo, setContactInfo] = useContext(GetContactInfoContext)
  const navigate=useNavigate()
  const user_username = JSON.parse(localStorage.getItem('user_username'));
  const {register, formState: { errors }, handleSubmit} = useForm()
  const user_token = JSON.parse(localStorage.getItem('user_token'))
  const user_org = JSON.parse(localStorage.getItem('user_org'))  // retrieves data from cache
  // sample data : "Testing,StevensonGG,Eustache,6786820502,80"
  // transform data into array format
  const gatherData = async(data)=>{
  // we need the following portion for backend processing. This will remove any item that is not updated and the backend will trigger a false.
  for (const [key, value] of Object.entries(data)) {
    // loops through the dictionary
    if (value === ''){
        // register data will always be an empty string if it is left blank. This portion of the function will delete anything with a blank line
        delete data[key];
        // this does the deleting
    }
  }    
    data.id = contactInfo.id
    // the id value of the user contact
    data.org = user_org
    // the user_org value saved in the cookies
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/update_api`,data,{headers : {"Authorization" : `token ${user_token}`}})
    // sends the form value to the backend to be updated
    Swal.fire({
      title: 'Data is being processed',
      confirmButtonText: 'Okay',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigate(-1)
      }
    })
    
  }
  const gatherUserCategory = async()=>{
    // data that will be sent to the API endpoint.
    const getCategorytDataApi = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin_category`,{"id":contactInfo.id, "username":user_username, "pageName":"admin"},{headers : {"Authorization" : `token ${user_token}`}})
    return(getCategorytDataApi.data)
    // this gets the specifc user category information
  } 
  const getCategory = async () => {
    const getApiData = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/category_api`,{"org":user_org, "username":user_username, "pageName":"admin"},{headers : {"Authorization" : `token ${user_token}`}})
    return(getApiData.data)
    // retrieves all the categroy data from the backend
  }
  const {data:categoryData, isLoading: catgeoryApiLoading, error: categoryApiError} = useQuery("getApiData", getCategory);
  // retrives all the category from from database
  const {data:gatherUserCategoryData, isLoading: gatherUserCategoryLoading, error: gatherUserCategoryError} = useQuery("getCategorytDataApi", gatherUserCategory)
  // retrieves the user selected specific category
  if (catgeoryApiLoading, gatherUserCategoryLoading)return<>Fetching data</>
  if (gatherUserCategoryError, categoryApiError)return<>
      {Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Why do I have this issue?</a>'
    })}</>
  console.log(contactInfo)
  return (
    <div>
      <TopNav prefBgColor="primary" prefFgColor="dark"/>
      <Container>
          <div className="container">
          <div className="row">
            <div>       
              <PageHeader headerTitle="Edit Contact"/>     
              <form className="row form rounded mx-auto mt-5 mb-5" onSubmit={handleSubmit(gatherData)}>
              {/* runs a function that will gather the form data and variable */}                      
              <Stack gap={4}>
                <div className="row mt-5">
                  <h1 className="col-6 mt-3">{contactInfo.first_name_info}:</h1>
                  <div className="col-6 mt-3 border border-success">
                  <FormInputBoxWithValidation
                    register={register}
                    errors={errors}
                    errorValidation={{ required: false }}
                    registeredName={"firstName"}
                    placeholder="Updated First Name"
                    inputType="text"
                    required={false}
                    characterValue={20}
                    patternValue={/^[A-Za-z- ]+$/i}
                    patternMessage={"can only have [A-Za-z- ] as charachter"}
                  />                                
                  </div>
                </div>
                <div className="row">
                  <h1 className="col-6 mt-3">{contactInfo.last_name_info}:</h1>
                  <div className="col-6 mt-3 border border-primary">
                      <FormInputBoxWithValidation
                        register={register}
                        errors={errors}
                        errorValidation={{ required: true }}
                        registeredName={"lastName"}
                        placeholder="Updated Last Name"
                        inputType="text"
                        required={false}
                        characterValue={20}
                        patternValue={/^[A-Za-z- ]+$/i}
                        patternMessage={"can only have [A-Za-z- ] as charachter"}
                      />
                </div>
                </div>
                <div className="row">
                  <h1 className="col-6 mt-3">{contactInfo.phone_number_info}:</h1>
                  <div className="col-6 mt-3 border border-danger">
                      <FormInputBoxWithValidation
                        register={register}
                        errors={errors}
                        errorValidation={{ required: true }}
                        registeredName={"phoneNumber"}
                        placeholder="Updated Phone Number"
                        inputType="tel"
                        required={false}
                        characterValue={30}
                        patternValue={/^(\+\d{1,3}[- ]?)?\d{10}$/}
                        patternMessage={"Allowed characters are digits only, no dash. 1114445555"}
                      />
                    </div>
                </div>
                <div className="row mt-4">
                  <h1 className="col-6 mt-3">{contactInfo.contact_notes_info}:</h1>
                  <div className="col-6 mt-3 border border-dark">
                      <FormInputBox 
                          heightSize="40vw"
                          styles={{ maxHeight: 200 }}
                          cols={100}
                          register={register}
                          registeredName={"notes"}         
                          placeholder="Updated Notes about contact" 
                          inputType="text"    
                          className="square border border-warning text-left m-5" 
                        /> 
                    </div>
                </div>
                <h1 className="p-1 mt-5 mb-1 text-center fs-1">Current category: {gatherUserCategoryData}</h1>
                <div className="col form-group d-flex justify-content-center">
                  {/* want to make this a full screen dropdown */}
                    <select                       
                      {...register("outreachCategory", { required: 'Outreach Category is required' })}
                      className="btn btn-primary dropdown-toggle form-group p-1 mt-3 mb-3 text-center fs-1"> 
                      <option value="placeholder">--Select Updated Category--</option>
                       {categoryData.map((eachItem)=>{
                        return <option value={eachItem} key={eachItem}>{eachItem}</option>
                      })
                      }                                                   
                    </select>
                </div>
                <div className="form-group row-12">
                    <div className="col text-center">
                      <PageButton buttonText="Submit" styleValue="btn btn-success btn-lg"/>
                    </div>
                </div>
                  </Stack>
                </form>
              </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AdminEditForm