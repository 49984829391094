import AuthRoutes from './AuthRoutes'
import { Navigate } from 'react-router-dom'
import Homepage from '../components/Homepage'
import Register from '../components/Register'
import NotFound from '../components/NotFound'
import Outreach from '../components/Outreach'
import CreateAccount from '../components/CreateAccount'
import Send from '../components/Send'
import Data from '../components/Data'
import Select from '../components/Select'
import LoginIdentity from '../components/LoginIdentity'
import MainLayout from '../components/MainLayout'
import Admin from '../components/Admin'
import AdminEditForm from '../components/AdminEditForm'

var landingPage
const user_token = JSON.parse(localStorage.getItem('user_token'));


if (user_token == null){
    landingPage = "login"
}
else{
    landingPage = "home"
}
const routes = [
    
    {path:"", element: <Navigate to={landingPage}/>}, // this is when user is not logged, landing page
    // this empty directory wil lead to login page
    {path:"login", element: <LoginIdentity/>},
    {path:"create" ,element:<CreateAccount/>},
    {path:"home", element: <AuthRoutes><MainLayout/></AuthRoutes>, children:[
        // anything within home directory will check the AuthRoutes compoenent
        // home is path that needs to be called for every URL
        // MainLayout passes the children components through it
        // each attempt of call
        {path:"", element: <Homepage/> },
        // each path defined 
        // everything that requires login will need to be passed inside of the home parent directory
        {path:"register", element: <Register/> },
        // each path defined 
        {path:"*" ,element:<NotFound/>},
        {path:"outreach" ,element:<Outreach/>},
        {path:'send' ,element:<Send/>},
        {path:'select' ,element:<Select/>},
        {path:'data' ,element:<Data/>},
        {path:'admin' ,element:<Admin/>},
        {path:'Admin/AdminEditForm/:id' ,element:<AdminEditForm/>},
    ]} ,
    
]
// each object within the array is a route

export default routes