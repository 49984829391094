import React from 'react'
import {Container, Stack,} from 'react-bootstrap'
import {useForm} from "react-hook-form"
import { useNavigate } from 'react-router-dom'
import TopNav from './TopNav'
import PageHeader from "../reusableComponents/PageHeader"
import FormInputBoxWithValidation from "../reusableComponents/FormInputBoxWithValidation"
import FormInputBox from "../reusableComponents/FormInputBox"
import PageButton from "../reusableComponents/PageButton";
import axios from "axios"
// axios for fetching
import { useQuery } from "react-query"
import Swal from 'sweetalert2'

const Register = () => {
  const navigate=useNavigate()
  const spinner = {
    width: "6rem", 
    height: "6rem"
}
  const user_token = JSON.parse(localStorage.getItem('user_token'))
  const user_username = JSON.parse(localStorage.getItem('user_username'))
  const user_org = JSON.parse(localStorage.getItem('user_org'))
  // retrieves token from local storage for authentication against the DB
  const {register, formState: { errors }, handleSubmit, reset} = useForm()
  const GatherData = async(data)=>{
    data.org = user_org
    data.username = user_username
    if (data.first_time_guest){
      data.first_time_guest = "yes"
    }
    
    // data that will be sent to the API endpoint
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/register_api`,data,{ Authorization: `token ${process.env.REACT_APP_API_TOKEN}`});
    Swal.fire('Contact Registered Successfully')
    reset()
    // sets the value of the form input fields to "empty". If not, the prevent default will hinder the page from re-rendering, and all the data will remain on screen.
    // so this sets the value to none, which will erase evything from the screen    
} 
  const getCategory = async () => {
    const getApiData = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/category_api`,{"org":user_org, "username":user_username, "pageName":"register"},{headers : {"Authorization" : `token ${user_token}`}})
    return(getApiData.data)
    // retrieves all the categroy data from the backend
  };
  const {data:categoryData, isLoading: catgeoryApiLoading, error: categoryApiError} = useQuery("getApiData", getCategory);
  // uses the useQuery pacakge data to pass data to the frontend
  if (catgeoryApiLoading)return<>    <div class="d-flex justify-content-center">
  <div className="spinner-border text-dark mt-5 display-5" style={spinner} role="status"></div>
</div></>
  if (categoryApiError)return<>
  {Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="mailto:evolvingtechnologies1@outlook.com?subject=Error Loading Page&body=Hey there, I am running into an error accessing this page. Please help me resolve issue.">Why do I have this issue?</a>'
  }).then((result) => {
      if (result.isConfirmed) {
          navigate(-1)
          setTimeout(() => {
              navigate(0)
          }, 3);
              }})}</>
  return (
    <div>
      <TopNav prefBgColor="primary" prefFgColor="dark"/>
      <Container>
        <div className="container">
          <div className="row">
            <PageHeader headerTitle="Register Contact"/>
            <form className="row form rounded mx-auto mt-5 mb-5" onSubmit={handleSubmit(GatherData)}>
              {/* runs a function that will gather the form data and variable */}                      
              <Stack gap={4}>
                <div className="row mt-5">
                  <span className="col-1 mt-3"><i className="fa fa-user bigicon text-primary col"></i></span>
                    <div className="col-10">
                      <FormInputBoxWithValidation
                        register={register}
                        errors={errors}
                        errorValidation={{ required: true }}
                        registeredName={"firstName"}
                        placeholder="First Name"
                        inputType="text"
                        required={true}
                        characterValue={20}
                        patternValue={/^[A-Za-z- ]+$/i}
                        patternMessage={"can only have [A-Za-z- ] as charachter"}
                      />                                
                    </div>
                </div>
                <div className="row">
                    <span className="col-1 mt-3"><i className="fa fa-users bigicon text-success"></i></span>
                    <div className="col-10">
                      <FormInputBoxWithValidation
                        register={register}
                        errors={errors}
                        errorValidation={{ required: true }}
                        registeredName={"lastName"}
                        placeholder="Last Name"
                        inputType="text"
                        required={true}
                        characterValue={20}
                        patternValue={/^[A-Za-z- ]+$/i}
                        patternMessage={"can only have [A-Za-z- ] as charachter"}
                      />
                    </div>
                </div>
                <div className="row">
                    <span className="col-1 mt-3"><i className="fa fa-phone-square bigicon text-dark"></i></span>
                    <div className="col-10">
                      <FormInputBoxWithValidation
                        register={register}
                        errors={errors}
                        errorValidation={{ required: true }}
                        registeredName={"phoneNumber"}
                        placeholder="Phone Number"
                        inputType="tel"
                        required={true}
                        characterValue={30}
                        patternValue={/^(\+\d{1,3}[- ]?)?\d{10}$/}
                        patternMessage={"Allowed characters are digits only, no dash. 1114445555"}
                      />
                    </div>
                </div>
                <div className="row mt-4">
                    <span className="col-12 mt-5 text-center"><i className="fa fa-pencil-square-o bigicon text-warning"></i></span>
                    <div className="col-12">
                      <FormInputBox 
                          heightSize="40vw"
                          styles={{ maxHeight: 200 }}
                          cols={100}
                          register={register}
                          registeredName={"notes"}         
                          placeholder="Notes about contact" 
                          inputType="text"    
                          className="square border border-warning text-left m-5" 
                        /> 
                    </div>
                </div>
                <div className="col form-group d-flex justify-content-center">
                  {/* want to make this a full screen dropdown */}
                    <select                       
                      {...register("outreachCategory", { required: 'Outreach Category is required' })}
                      className="btn btn-primary dropdown-toggle form-group p-1 mt-3 mb-3 text-center"> 
                      <option value="placeholder">--Select Category--</option>
                       {categoryData.map((eachItem)=>{
                        return <option value={eachItem} key={eachItem}>{eachItem}</option>
                      })
                      }                                                   
                    </select>
                </div>
                <div className="mx-auto d-flex justify-content-center">
                <label className="mx-auto">
                  <FormInputBoxWithValidation
                    register={register}
                    errors={errors}
                    errorValidation={{ required: true }}
                    registeredName={"first_time_guest"}
                    inputType="checkbox"
                    required={false}
                  />First Time Guest 
                </label>                   
                </div>
                <div className="form-group row-12 mt-5">
                    <div className="col text-center">
                      <PageButton buttonText="Submit" styleValue="btn btn-success btn-lg"/>
                    </div>
                </div>
              </Stack>
            </form>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Register
