import React from "react";
import {useForm} from "react-hook-form";
import {Container} from "react-bootstrap";
import FormInputBoxWithValidation from "../reusableComponents/FormInputBoxWithValidation";
import PageHeader from "../reusableComponents/PageHeader";
import PageButton from "../reusableComponents/PageButton";
import Swal from 'sweetalert2'
// alert boxes
import "./LoginIdentity.css";
import axios from "axios";

const CreateAccount = () => {
    const {register, formState: { errors }, handleSubmit, reset} = useForm()
    // https://react-hook-form.com/api/useform/formstate
        // When you call watch, you pass it the registered name you want it to watch, this watches it's every input
        // You can also keep it all blank and it watches everything as on object
        // handleSubmit() => gets all the input values and gives it to us. (this does not catch every press)
        // repeatable function that will be used for API 
        // reset resets the form value to blank 
    const GatherData = async (data) => {
        const api_resp = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create_account`,data,{headers : {"Authorization" : `token ${process.env.REACT_APP_API_TOKEN}`}});
        // used to post data to the backend using external component
        if (api_resp.data === "account created"){
          reset()
          // if account is created without error, then the following code is processed. 
          // the data will come from backend, and ReactJS will catch it in the frontend
          Swal.fire({
            title: 'Account is created',
            text: "Keep an eye out on your spam/junk email for account creation approval. Contact admin if you do not receive account activation confirmation email within 48 hours.",
            icon: 'success',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Next step'
            // sweetalert box. 
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: 'Onboarding',
                text: "Please select 'next step' if this is the first time that your organization is signing up.",
                icon: 'question',
                showCloseButton: true,
                showDenyButton: true,
                confirmButtonColor: '#3085d6',
                denyButtonColor: '#d33',
                confirmButtonText: 'Next step',
                denyButtonText: 'Not first time'
              })
              .then((result) => {
                console.log(result)
                if (result.isConfirmed) {
                  window.open("https://form.jotform.com/evolvingtechnologies1/organization-creation-form", "_self")
                }
                else if (result.isDenied){
                  console.log("false")
                  window.open("https://youtube.com/playlist?list=PLn_L2l-aq7k0MPfmvkfIWTn2teL-ZMKjE", "_self")
            }
          })
        }})}
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Account was not created.',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText:'Try again!',
            footer: '<a href="mailto:evolvingtechnologies1@outlook.com?subject=Account Creation Error&body=Please provide the first name, last name, username, email and organization that you provided on the form. A tech will contact you soon">Click here if issue persist more then 3 times</a>'
          })
          // alert box that user will receive upon completion of account creation
          reset()
          // reset resets the form value to blank
        }
      }
  return (
    <Container className="w-75 h-100 mx-auto">
      {/* actual form */}
      <PageHeader headerTitle="Create Account" />
      <form className="row form rounded mx-auto" onSubmit={handleSubmit(GatherData)}>        
        {/* GATHER FORM DATA WITH THE EVENT HANDLER. */}
        {/* Register is being passed from useFrom from ABOVE which adds it to its formstate */}
        <FormInputBoxWithValidation
          register={register}
          errors={errors}
          errorValidation={{ required: true }}
          registeredName={"firstName"}
          placeholder="First Name"
          inputType="text"
          required={true}
          characterValue={20}
          patternValue={/^[A-Za-z- ]+$/i}
          patternMessage={"can only have [A-Za-z- ] as charachter"}
        />
        <FormInputBoxWithValidation
          register={register}
          errors={errors}
          errorValidation={{ required: true }}
          registeredName={"lastName"}
          placeholder="Last Name"
          inputType="text"
          required={true}
          characterValue={20}
          patternValue={/^[A-Za-z- ]+$/i}
          patternMessage={"can only have [A-Za-z- ] as charachter"}
        />
        <FormInputBoxWithValidation
          register={register}
          errors={errors}
          errorValidation={{ required: true }}
          registeredName={"username"}
          placeholder="Username"
          inputType="text"
          required={true}
          characterValue={30}
          patternValue={/^[A-Za-z-0-9]+$/i}
          patternMessage={"Allowed characters are letters, digits and @/./+/-/_ only."}
        />
        <FormInputBoxWithValidation
          register={register}
          errors={errors}
          errorValidation={{ required: true }}
          registeredName={"organization"}
          placeholder="organization"
          inputType="text"
          required={true}
          characterValue={300}
          patternMessage={"Allowed characters are letters, digits and @/./+/-/_ only."}
        /> 
        <FormInputBoxWithValidation
          register={register}
          errors={errors}
          errorValidation={{ required: true }}
          registeredName={"email"}
          placeholder="Email"
          inputType="email"
          required={true}
          characterValue={60}
          patternValue={/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-z]+)$/}
          patternMessage={"Allowed characters are letters, digits and @/./+/-/_ only."}
        />
        <FormInputBoxWithValidation
          register={register}
          errors={errors}
          errorValidation={{ required: true }}
          registeredName={"password"}
          placeholder="Password"
          inputType="password"
          required={true}
          characterValue={100}
          patternValue={/^.{1,50}$/}
          patternMessage={"Allowed characters are letters, digits and @/./+/-/_ only."}
          extraRules={{
            minLength: {
              value: 8,
              message: `Have to be more then 8 characters`
            }
          }}
        />
        <div className="w-100 mx-auto text-center text-danger font-weight-light font-italic">
          <p className="fw-bolder w-100 mx-auto text-center font-weight-bold text-uppercase text-danger">IMPORTANT</p>
          <p>Username and Password cannot be similar</p>
          <p>Minimum of 8 characters </p>
          <p>Password cannot be a common password</p>
          <a href="https://en.wikipedia.org/wiki/Wikipedia:10,000_most_common_passwords#Other_common_passwords">see list of common example</a>
        </div>
        <div title="Log in"/>
         {/* submit button to form */}
        <div className="row mt-3 mb-5 pt-2 pb-2 w-75 mx-auto border border-light border-0 rounded" id="button">
          <PageButton styleValue="font-weight-bold display-5 mt-3 btn btn-primary" buttonText="Create"/>
        </div>
      </form>
    </Container>
  );
};

export default CreateAccount;
