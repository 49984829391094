import React from 'react'
import {Navbar, Nav, Button}from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

function TopNav(props) {

  return (
      <Navbar bg={props.prefBgColor} variant={props.prefFgColor} className="container-fluid d-flex justify-content-around w-100">
        <Nav>
          <Button onClick={() => window.open("/home", "_parent")}>Home</Button>
          <Button onClick={() => window.open("register", "_parent")}>Register</Button>
          <Button onClick={() => window.open("select", "_parent")}>Send Message</Button>
          <Button onClick={() => window.open("outreach", "_parent")}>Outreach</Button>
          <Button onClick={() => window.open("data", "_parent")}>Fire Trackers</Button>
          <Button onClick={() => window.open("admin", "_parent")}>Admin</Button>
        </Nav>
      </Navbar>

  )
}

export default TopNav