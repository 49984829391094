const PageHeader = (props) => {
  return (
    <div className="mt-5">
      <h2 className="text-center text-dark">
        {props.headerTitle}
      </h2>
    </div>
  );
};

export default PageHeader;
