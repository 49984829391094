import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from "react-query";
import {Container, Stack}from 'react-bootstrap'
import {useForm} from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import Swal from 'sweetalert2'
import PageButton from "../reusableComponents/PageButton"
import PageHeader from "../reusableComponents/PageHeader"
import TopNav from './TopNav'
import { SelectMessageOptionContext } from '../context/SelectMessageOption'
import { Image, CloudinaryContext } from 'cloudinary-react';

const Send = ()=>{
  const [image, setImage] = useState();
  const [message, setMessage] = useState('');
  const [imageRemoval, setImageRemoval] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [selectOption] = useContext(SelectMessageOptionContext)
  // used to pass the value from the send message landing page. 
  const [selectView, setSelectView] = useState(true)
  const [showRecipient, setShowRecipient] = useState([])
  const [textView, setTextView] = useState(false)
  const [viewRecipient, setViewRecipient] = useState(false)
  const [textareaheight, setTextareaheight] = useState(1); 
  const [selectContact, setSelectContact] = useState('true')
  const navigate=useNavigate()
  const user_username = JSON.parse(localStorage.getItem('user_username'))
  const api_token = JSON.parse(localStorage.getItem('user_token'))
  const user_org = JSON.parse(localStorage.getItem('user_org'))
  // retrive save token from storage
  const {register, handleSubmit, reset} = useForm({mode: "onChange"});
  // used for react-hook-from checkbox
  // URL for API endpoint
  const onSubmit = async(data) => {  
    setSelectContact(data)
    setSelectView(false)   
    setTextView(data.value)
    reset()
    }
  const sendText = async(data) => {
    data.value = selectContact
    Swal.fire({
      title: 'Did you proofread yet?',
      text:data.sendTextMessage,
      focusConfirm: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async(result)=>{
      if(result.isConfirmed){
        if (image === null){
          data.username = user_username
          data.user_org = user_org
          data.message = data.sendTextMessage
          data.org = user_org
        }
        else{
          data.username = user_username
          data.user_org = user_org
          data.message = data.sendTextMessage
          data.org = user_org
          data.file = imageUrl
        }

        if (selectOption === "contact_api"){
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/contact_email_api`,data,{headers: {Authorization: `token ${api_token}`, 'Accept': 'application/json', 'Content-Type': 'application/json'}})     
          navigate(-1)
        }
        else if (selectOption === "category_api"){
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/category_email_api`,data,{headers: {Authorization: `token ${api_token}`}})
          navigate(-1)
        }
        else if (selectOption === "outreach_api"){
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/minister_email_api`,data,{headers: {Authorization: `token ${api_token}`}})
          navigate(-1)
        }
        else if (selectOption === "all_outreach"){
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/outreach_contact_email_api_data`,data,{headers: {Authorization: `token ${api_token}`}})
          navigate(-1)
        }
      }
    })}
  const api_fetch = async() => {
    const apiData = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/`+selectOption+"?format=json",{"org":user_org, "username":user_username, "userView":"outreachContacts"},{headers : {"Authorization" : `token ${process.env.REACT_APP_API_TOKEN}`}})
    // API call
    return apiData.data
    // react hook from passes it as a param to handlesubimt so its localized to that.
  }
  const spinner = {
    width: "6rem", 
    height: "6rem"
}
  const handleChange = (event) => { 
    // https://upmostly.com/tutorials/autosizing-textarea-react
    const height = event.target.scrollHeight; 
    const rowHeight = 15; 
    const trows = Math.ceil(height / rowHeight) - 1; 
    if (trows, textareaheight) { 
      setTextareaheight(trows); 
      
    } 
    
  } 
  const recipientApiRequest = async() => {
    const apiRecipientApiRequestData = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/view_recipient`,{"org":user_org, "username":user_username, "groupType": selectOption, "minister":textView},{headers : {"Authorization" : `token ${process.env.REACT_APP_API_TOKEN}`}})
    await setShowRecipient(apiRecipientApiRequestData.data)
    setViewRecipient(true)
  }
  const removeUpload = () => {
    if (image){
      setImageRemoval("File is unattached")
      setImage(null)
    }
    else{
      setImageRemoval("No file to unattach")
    }

  }
  const handleUpload = (e) => {
    setImage(e.target.files[0]);
  };
  
  useEffect(() => {
    if (image) {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('upload_preset', 'allnationsxyz');
      axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, formData)
        .then((response) => {
          setImageUrl(response.data.secure_url)
          setMessage('Upload successful!');
        })
        .catch((error) => {
          console.error(error);
          setMessage('Upload failed!');
        });
    }
  }, [image]);

  const {data:apiFetchData, isLoading:loadingFetchData, error:errorFetchData} = useQuery("apiData", api_fetch)

  // used with useQuery
  if (loadingFetchData){ return <><>
    <div class="d-flex justify-content-center">
        <div className="spinner-border text-dark mt-5 display-5" style={spinner} role="status"></div>
    </div>
</></>}
  if (errorFetchData){return <>      
  {Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Something went wrong!',
    footer: '<a href="mailto:evolvingtechnologies1@outlook.com?subject=Error Loading Page&body=Hey there, I am running into an error accessing this page. Please help me resolve issue.">Why do I have this issue?</a>'
}).then((result) => {
    if (result.isConfirmed) {
        navigate(-1)
        setTimeout(() => {
            navigate(0)
        }, 3);
            }})}</>}  
  return (
    <CloudinaryContext cloudName={process.env.REACT_APP_CLOUD_NAME}>
      <div>
        <TopNav prefBgColor="primary" prefFgColor="dark"/>
        <Container  className="mt-5 mb-5">
        {selectView ? <>
          <PageHeader headerTitle="Select" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={3} className="mb-5">        
            {apiFetchData.map((eachItem) => {
                  return  <label>
                    <input 
                      type="checkbox" 
                      name={eachItem} 
                      key={eachItem} 
                      value={eachItem} 
                      {...register("value")}/>
                      {eachItem}
                  </label>})}    
              <PageButton styleValue="font-weight-bold display-5 mt-3 btn btn-success" buttonText="Send message"/>
            </Stack>
          </form>
          </> : <></>}
          {textView ? <>
            <div class="form-group">
              <form onSubmit={handleSubmit(sendText)}>
              <Stack gap={5} className="mb-5">
              <PageHeader headerTitle="Send Text" />
                <textarea {...register("sendTextMessage")} class='form-control' rows={textareaheight} onChange={handleChange}  spellCheck="true" placeholder="Enter text to send" required></textarea>
                <PageButton styleValue="font-weight-bold display-5 mt-3 btn btn-success btn-small" buttonText="Send message"/>
              </Stack>
              </form>
              <div>
                <input type="file" onChange={handleUpload} />
                <button className="font-weight-bold display-5 mt-3 btn btn-dark btn-small" onClick={removeUpload}>Remove upload</button>
                <br/>
                <br/>
                <h5>{imageRemoval}</h5>
              </div>
              {selectOption === "outreach_api" || selectOption === "category_api"  ? <>
              <Stack gap={5} className="mb-5">
                  <button className="font-weight-bold display-5 mt-3 btn btn-dark btn-small" onClick={recipientApiRequest}>View recipient</button>
              </Stack>
              
              </> : <>{null}</>}
              {viewRecipient ? <>
              {showRecipient.map((eachContact)=>{
                return(
                  <div className="row-12">
                    <h1 className="col m-3">{eachContact}</h1>
                  </div>
                )
              })}</> : <>{null}</>}
          </div>
          </> : <></>}
        </Container>
      </div>
    </CloudinaryContext>

  )           
}

export default Send