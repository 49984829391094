import './App.css';
import React from 'react'
import { useRoutes } from "react-router-dom";
import { Stack } from 'react-bootstrap'
import TemplateFooter from './components/TemplateFooter';
import routes from './authentication/routes'
import axios from 'axios';
import { GetContactInfoProvider } from './context/GetContactInfo';
import { SelectMessageOptionProvider } from './context/SelectMessageOption';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

function App() {
  const content = useRoutes(routes)
  
  // must create useState in parent in order to share data between siblings component
  return (
    <SelectMessageOptionProvider>
      <GetContactInfoProvider>
        <Stack gap={5}>  
          {content}
          <TemplateFooter />
        </Stack>
      </GetContactInfoProvider>
    </SelectMessageOptionProvider>
  )
}

export default App;